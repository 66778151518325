#root, .app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /*height: 100vh;*/
}


body{
    background-color: #282B36;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    color:#fff;

}

a, img, h1, p, h2, button {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}


.mobile {
    display: block;
}

.desktop {
    display: none;
}


.flex-1 {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}


.relative {
    position: relative;
}


.icon-wrapper{
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 70px;
    /*height: 104px;*/
    cursor: pointer;
}

.icon-wrapper.selected .icon-image{
    border: 1px solid #dae1e7;
}

.icon-wrapper.selected .icon-name span {
    white-space: pre-wrap;
    background-color: #2779bd;
    color: #fff;
}

.just4Fun-dark-blue-color {
    color: #2A3474
}


.icon-name {
    color: #22292f;
    font-size: .875rem;
    text-align: center;
    pointer-events: none;
    margin-top: .25rem;
}

.icon-name span {
    padding-left: .25rem;
    padding-right: .25rem;
}

.icon-wrapper .icon-image{
    border: 1px solid transparent;
    /*width: 64px;
    height: 64px;*/

    background-size:100%;
    background-repeat: no-repeat;
    pointer-events: none;
    
}

.z-10 {
    z-index: 10;
}

#email-icon {
    bottom: 50px;
    right: 11px;
}


.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}

.blueColor{
    color: #707DB1;
}


.login-box{
    
    
    max-width: 90%;
    position: relative;
}

.footer {
    position: fixed;
    bottom: 10px;
    color: #4A4E5B;
    font-size: 0.7rem;
    text-align: center;

}

.footer button {
    color:#BDBDBD;
    margin-bottom: 10px;
    font-size: 0.9rem;
    padding-bottom: 2px;
    border-bottom: 1px solid #BDBDBD;
}

.footer button:hover {
    opacity: 0.8;
}

.login-box h3{
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.login-box input {
    background-color: #373A44;
}

.login-box button {
    background-color: #3B3F4E;
    color:#BDBDBD;
    font-size: 1.2rem;
}

.login-box button:hover {
    opacity: 0.8;
}

.login-box  .close-icon-modal {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    cursor: pointer;
}
.login-box h1, .login-box a{
    color:#fff;
}

.login-box a{
    text-decoration: underline;
}


.login-box img {
    max-width: 100%;
    width:400px;
}

.error {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    color:#E7B3BB;
}

.bg-grey {
    background-color: #DEDEDE;
}


.bg-grey:hover {
    background-color: #DEDEEE;
}

.top-bar {
    
    width: 100%;
    flex-direction: row;
    height: 90px;
    min-height: 90px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #B5B5B5;
}

.top-bar input , .header-input-container input{
    background: #4A4E5B;
    border-radius: 10px;
    font-size: 14px;
    flex:1;
    margin-left: 25px;
    margin-right: 20px;
    padding: 10px 20px;
    height: 40px;
    line-height: 40px;
    color: #BDBDBD;
}

.header-line {
    width: 100%;
    display: block;
    height: 2px;
    background-color: #4A4E5B;
}
.top-bar h1{
    text-transform: uppercase;
    font-size: 24px;
    color: #BDBDBD;
    flex: 1;
    text-align: center;
}

.mobile.top-bar {
    display: flex;
    justify-content: space-between;

}

.top-bar button {
    margin: 0 40px;
    padding: 0 40px;
    font-weight: bold;
}

.top-bar button:focus {
    outline: 0;
}

 .top-bar button.logout {
    position: fixed;
    text-decoration: underline;
    top:25px;
    right: 0px;
    font-size: 25px;
    font-weight: normal;
    margin: 0;
    color: #BDBDBD;
}

.top-bar button:hover, .top-bar button.current {
    color: #fff;
}

.menu-toggle {
    cursor: pointer;
    position: absolute;
    width: 30px;
    margin-left: 20px;
    top:37px;
}

.back-arrow {
    position: absolute;
    width: 16px;
    margin-left: 20px;
    top: 33px;
    cursor: pointer;
}

.empty-space-mobile-left {
    display: block;
    width: 30px;
    margin-left: 20px;
}
.top-bar .close-icon {
    width: 44px;
}

.close-icon {
    cursor: pointer;
}

.newMessagePopUp {
    position: fixed;
    z-index: 100000;
    background: #373A44;
    border: 2px solid;
    border-radius: 20px;
    padding: 20px 0;
    width: 260px;
    height: 194px;
    text-align: center;
    top: calc(50% - 67px);
    left: calc(50% - 130px);
}

.newMessagePopUp p {
    margin-bottom: 20px;
}

.newMessagePopUp h1 {
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 2px solid;
    margin-bottom: 20px;
}

.newMessagePopUp button {
    text-decoration: underline;
}

.newMessagePopUp button:first-child {
    opacity: 0.8;
}

.modal {
    position: fixed;
    width:100%;
    /*height: 100vh;*/
    background-color: #fff;
    left:0;
    top: 0;
    max-width: 100%;
    border: 1px solid #B3B3B3;
    /*min-height: 100vh;*/
}

.modalDiv{
    width: 100%;
    height: 100%;
}

.modalcontainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.modalcontainer > .header{
    background-color: #B3B3B3;
    text-align: center;
    min-height: 50px;
    line-height: 24px;
    font-weight: bold;
    position: relative;

    padding: 13px 39px;
}

.modalcontainer .close{
    height: 50px;
    width: 50px;
    position: absolute;
    background-image: url("https://cdn.huntakiller.com/huntakiller/s8/closebutton.svg");
    background-position: center;
    background-size: 100%;
    right: 0px;
    top: 0px;
}

.ScrollbarsCustom{
    width: 100%;
    height: 100%;
    display: block;
    flex: 1 1;
}

.imageViewer{
    position: relative;
    width: 100%;

    /*height: calc(100% - 90px);*/
}




    .bottom-imageViewer-controllers button,  .bottom-imageViewer-controllers a{
        height: 50px;
        width: 50px;
        margin-top: 7.5px;
        
        
        margin-left: 15px;
        margin-right: 15px;
        text-align: center;

        
     }


     .bottom-imageViewer-controllers a{
        float: right;
        font-size: 0.6rem;
        line-height: 25px;

     }

     .bottom-imageViewer-controllers a img {
        height: 50px;
     }


    .bottom-imageViewer-controllers button:focus{
        border: none;
        outline: none;
    }

.content-file{
    max-width: 100%;
    max-height: 100%;
}


.rtfdoc{
    padding: 20px;
    font-size: 12px;
    width:calc(100% );


}

object{
    max-width: 100%;
}

.rtfdoc h2, .rtfdoc p, .rtfdoc object{
    margin-bottom: 15px;
}

.rtfdoc h2{
    font-weight: bold;
    font-size: 14px;
}

.rtfdoc ul {
    list-style: disc;
    padding-left: 15px;
    margin-bottom: 15px;
}

.rtfdoc ol {
    padding-left: 30px;
    margin-bottom: 15px;
    list-style: decimal;
}

.rtfdoc ul li, .rtfdoc ol li{
    margin-top: 10px;
    margin-bottom: 10px;
}

.rtfdoc a{
    color:blue;
    text-decoration: underline;
}

.rtfdoc table{
    min-width: 120%;
    width: 100%;
}
.rtfdoc table th, .rtfdoc table td {
    padding:10px;
}
.rtfdoc table, .rtfdoc th, .rtfdoc td {
  border: 1px solid black;
}

.rtfdoc table tr:nth-child(even) {
  background-color: #eee;
}
.rtfdoc table tr:nth-child(odd) {
  background-color: #fff;
}

.strikethrough{
    text-decoration: line-through;
}

.ashleyBlogSite {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    color:#414141;
     padding-top: 64px;
}


.ashleyBlogSite h1 {
    color: #A9654C;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
   
}

.ashleyBlogSite form {
    width: 200px;
    max-width: 80%;
    text-align: center;
}

.ashleyBlogSite form button , .orangebg{
    background-color: #A9654C;
}

.orange {
    color: #A9654C;
}

.ashleyBlogSite .header, .ashleysOfflineMessageSite .header {
    background-color: #A9654C;
    text-align: center;
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top:0;
    z-index: 2;
}

.ashleyBlogSiteUpload.ashleyBlogSite {
    padding-top: 0;
}
.ashleyBlogSiteUpload.ashleyBlogSite .header {
    position: relative;
    height: auto;
}


.ashleyBlogSiteUpload .dropdown {
    width: 300px;
    max-width: 80%;
    
    
    overflow: visible;
    height: 63px;

}

.ashleyBlogSiteUpload .dropdown > div {
    background-image: url("https://cdn.huntakiller.com/huntakiller/s10/ashleyblog/dropdownarrow.svg");
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: right 20px top 25px;
    background-size: 22px 12px;
    cursor: pointer;
}

.ashleyBlogSiteUpload .progress-bar {
    width: 300px;
    max-width: 80%;
}


.ashleyBlogSite .footer {
    background-color: #A9654C;
    height: 71px;display: block;
    width: 100%;
    min-height: 71px;
    position: relative;
    bottom: 0;
}

.ashleyBlogSite .header .menu {
    display: flex;
    font-size: 35px;
    color: #fff;
}

.ashleyBlogSite .header .menu li {
    padding: 0 30px;
    cursor: pointer;
}

.ashleyBlogSite .header .menu li:hover, .ashleyBlogSite .header .menu li.current {
    text-decoration: underline;
}

.ashleyBlogSite .header .close-icon, .ashleyBlogSite .cheeseburgerMenu .close-icon, .ashleysOfflineMessageSite .close-icon {
    width: 25px;
    top: 19px;
    right: 12px;
    position: absolute; 
    cursor: pointer;
    z-index: 2;
}



.ashleyBlogSite .cheeseburgerMenu .cheeseburgerMenu-inner {
    width: 100%;
}
.ashleyBlogSite .cheeseburgerMenu .menu {
    font-size: 23px;
    font-weight: bold;
    padding:0;
    color: #fff;
}

.ashleyBlogSite .cheeseburgerMenu .menu {
    border-top:  1px solid white;
}


.ashleyBlogSite .cheeseburgerMenu .menu li {
    padding: 30px 0;
    border-bottom: 1px solid white;
    cursor: pointer;
}

.ashleyBlogSite .cheeseburgerMenu .menu li.current {
    background-color: rgba(222,182,182,0.4);
}


.ashleyBlogSite .menu-toggle {
    width: 35px;
    top: 22px;
}

.ashleyBlogSite .cheeseburgerMenu {
    background-color: #A9654C;
}

.ashleyBlog-container {
    display: flex;
    flex-direction: column-reverse;
}

.ashleyBlog-container .side-bar img {
    width: 70px;
}

.ashleyBlog-container  .blog-container {
    border-top:1px solid #D0D0D0;
}



.ashleyBlogSite .read-more {
    color: #A9654C;
    text-decoration: underline;
}


.ashleysOfflineMessageSite {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    color:#414141;
}

.ashleysOfflineMessageSite.lockScreen {
    background-color: #FBB12A;
}

.ashleysOfflineMessageSite.lockScreen .login-box {
    flex: 1;
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
}

.ashleysOfflineMessageSite.lockScreen .login-box img {
    width: 300px;
    max-width: 80%;
    margin-bottom: 10%;
}

.ashleysOfflineMessageSite.lockScreen .login-box input {
    background-color: #fff;
}

.ashleysOfflineMessageSite.lockScreen .login-box input:first-child {
   background-image: url(https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/lock-icon.svg);
    background-repeat: no-repeat;
    background-size: auto 60%;
    background-position: right 30px center;
}

.ashleysOfflineMessageSite.lockScreen .login-box .error {
    color: #414141;
}
.ashleysOfflineMessageSite.lockScreen .login-box input:disabled {
    color: #414141;
}

.ashleysOfflineMessageSite.lockScreen .login-box input:focus {
    outline: none;
    box-shadow: none;
}

.ashleysOfflineMessageSite.lockScreen .login-box button {
    background-color: #000;
    color:#fff;
    max-width: 70%;
    width: 300px;
    margin: 0 auto;
}

.ashleysOfflineMessageSite .menuStorage {
    background-color: #D8D8D8;
}

.ashleysOfflineMessageSite .chat-thread {
    background-color: #fff;
}

.ashleysOfflineMessageSite .header {
    background-color: #FBB12A;
    border-bottom: none;
    box-shadow: 0 4px 7px -2px grey;

}

.ashleysOfflineMessageSite .chat-thread {
    border-bottom: 1px solid #979797;
}

.ashleysOfflineMessageSite .chat-thread:first-of-type {
    border-top:none;
}

.ashleysOfflineMessageSite .header h1 {
    color: #000;
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    width: 100%;
}

.ashleysOfflineMessageSite .avatar {
    border-color: #FBB12A;
    border-width: 1.5px;
    background-color: #fff;
    border-radius: 100%;
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    text-align: center;
    line-height: 37px;
}

.ashleysOfflineMessageSite .keepsafe-container {
        margin-top:64px;
}

.ashleysOfflineMessageSite .back-arrow {
    width: 30px;
    top:20px;
    z-index: 2;
    cursor: pointer;
}

.ashleysOfflineMessageSite  .add-button-chat img{
    width: 52px;
     cursor: not-allowed;
    min-width: 52px;
}

.ashleysOfflineMessageSite .HAKEmailChatContainer {
    border-top: none;
    margin: 0;
}
.ashleysOfflineMessageSite .HAKEmailChatContainer form {
    border-top:1px solid #D0D0D0;
}

.ashleysOfflineMessageSite .keepsafe-container.message-container-content .HAKEmailChatContainer .form-control {
    background-color: #E8E8E8;
    border:2px solid #979797;
    background-image: url(https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/input-bar-icon-1.svg), url(https://cdn.huntakiller.com/huntakiller/s10/ashelysofflinemessage/input-bar-icon-2.svg);
    background-size: auto 95%, auto 95%;
    background-position: left 10px center, right 10px center;
    background-repeat: no-repeat;
    cursor: not-allowed;
}

.ashleysOfflineMessageSite .keepsafe-container.message-container-content .HAKEmailChatContainer .chat-jpg {
    width: 50px;
}

.ashleysOfflineMessageSite .HAKEmailChatContainer .hak-chat-received {
    background-color: #FBB12A;
    color: #000;
}

.ashleysOfflineMessageSite .HAKEmailChatContainer .timestamp {
    color: #BDBDBD;
    font-size: 12px;
    text-align: center;
}

.ashleysOfflineMessageSite .HAKEmailChatContainer .hak-chat-sent {
    background-color: #BDBDBD;
    color: #000;
}

.ashleysOfflineMessageSite .emoji {
    font-size: 70px;
}

.blog_excerpt_container_gallery {
    background-color: #D8D8D8;
}

.blog_excerpt_container,.comment-container {
    border-bottom:1px solid #D0D0D0;
}

.blog_excerpt_container .wistia_embed {
    width: 200px;
    height: 150px;
}

.comment-container .avatar {
    width: 50px;
    min-width: 50px;
}

.keepsafe-main-locked-screen .header{
    background-color: #243D7E;
    padding: 2% 0;
}


.keepsafe-main-screen > .header  {
    background-color: #fff;
    padding: 5%;
    border-bottom: 2px solid #243D7E;
}

.keepsafe-main-screen .menu-item { 
    color: #243D7E;
    opacity: 0.6;
    cursor: not-allowed;
    text-transform: none;
    font-weight: normal;
}

.keepsafe-main-screen .menu-item:first-child {
    opacity: 1;
    cursor: pointer;
}

.keepsafe-main-locked-screen, .keepsafe-main-locked-screen-content  {
    flex: 1;
    display: flex;
    flex-direction: column;
    
}

.keepsafe-container-content {
    flex: 1;
    margin-top: 2%;
    color: #243D7E;
    overflow: auto;
}

.keepsafe-container.message-container-content, .keepsafe-container.message-container-content .keepsafe-container-content {
    display: flex; 
}

.keepsafe-container.message-container-content .keepsafe-container-content.desktop  {
    display: none;
}


.HAKEmailChatContainer .header-chat{
    border-bottom: 1px solid #4A4E5B;
}

.HAKEmailChatContainer .header-chat p {
    margin-bottom: 0;
}


.keepsafe-container.message-container-content .HAKEmailChatContainer .form-control {
    background-color: #373A44;
    border:none;
    color: #fff;
    padding: 1.5rem 1.25rem;
    font-size: 14px;
}

.send-button-chat {
    right: 18px;
    width: 21px;
    top: 16px;  
    cursor: pointer;
}

.send-button-chat.disabled {
    cursor: not-allowed;
    opacity: 0.2;
}


.HAKEmailChatContainer .hak-chat-received , .HAKEmailChatContainer .hak-chat-sent{
    font-size: 14px;
    font-weight: 500;
}


.keepsafe-container.message-container-content .HAKEmailChatContainer .form-control:focus {
    outline: none;
    box-shadow: none;
}


.keepsafe-container-content h3 {
    font-size: 36px;
    border-bottom: 1px solid #243D7E;
    margin-bottom: 3%;
}

.keepsafe-container-content h3 span {
    cursor: pointer;
}

.keepsafe-container-content > p {
    font-weight: 500;
    margin-bottom: 1%;
}

.keepsafe-iconlist {
    width: 100%;
    display: flex;
    color: #BDBDBD;
    font-size: 14px;
    flex-flow: row wrap;
}


.keepsafe-iconlist li{
    cursor: pointer;
    padding: 25px 0px;
    text-align: center;
    width: 90px;
    margin:0 15px;
   
}

.keepsafe-iconlist li:hover {
    opacity: 0.9;
}

.keep-safe-icon-container {
    display: flex;
    height: 45px;
    align-items: center;
}

.keep-safe-icon-container img {
    margin: 0 auto;
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
}


.keepSafeSite .login-box {
    color: #243D7E;
    background-color: #9FAAC5;
    width: 410px;
}

.keepSafeSite .login-box h2 {
    font-weight: bold;
    border-bottom: 4px solid #243D7E;
    font-size: 22px;
}

.keepSafeSite .login-box p {
    font-size: 18px;
}

.keepSafeSite .login-box input {
    border:2px solid #243D7E;
    font-weight: bold;
    color: #243D7E;
    font-size: 20px;
}

.keepSafeSite .login-box .error {
    color:#D35298;
}


.keepSafeSite .login-box button {
    background-color: #243D7E;
    color: #9FAAC5;
    border-radius: 20px;
}

.keepsafe-main-locked-screen-content {
    align-items: center;
    justify-content: center;


}

.keepsafe-main-locked-screen .header .logo {
    margin: 0 auto;
}

.keepsafe-main-screen > .header .logo{
    margin-left: 50px;
    cursor: pointer;
}

.keepsafe-main-screen .modalcontainer > .header , .keepsafe-main-screen .bottom-imageViewer-controllers{
    color: #243D7E;
    background-color: #9FAAC5;
}

.keepsafe-main-screen .bottom-imageViewer-controllers{
    
    background-color: #9FAAC5;
}



.keepSafeSite  .header .close-icon{
    width: 45px;
    position: absolute;
    right: 0rem;
    top:0rem;
    cursor: pointer;
}
.keepSafeSite  .header .menu-toggle-icon{
    width: 26px;
    position: absolute;
    top: 1rem;
    left: 1rem;
}

.keepSafeSite .header .menu-toggle-icon {
    background-color: #243D7E;
    padding: 10px;
    width: 46px;
    border-radius: 13px;
}

.keepsafe-search-container {
    margin-top: 20px;
    opacity: 0.6;
    cursor: not-allowed;
}

.keepsafe-header-right-icons {
    position: absolute;
    top: 12px;
    right: 45px;
    width: 110px;
    opacity: 0.6;
    cursor: not-allowed;
}


.keepsafe-main-screen {
    flex-direction: column;
    flex: 1;
    display: flex;
}
.keepsafe-container {
    display: flex;
    flex: 1;
    overflow:auto;
    /*overflow:auto;*/
    /*overflow: hidden;*/
    /*height: 553px;
    max-height: 553px;*/
    min-width: 0; 
    min-height: 0;
}

.keepsafe-header {
    color: #717688;
    position: relative;
}


.keepsafe-header  h2 {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 43px;
}
.keepsafe-main-screen .menu-item img {
    margin-right: 20px;
}


.ashleyBlogSite .header img {
    display: inline-block;

}

.ashleyBlogSite .header img.logo {
    max-width: 60%;
    margin:0 auto;
}

.cheeseburgerMenu{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    top: 0;
    z-index: 2;
}

.cheeseburgerMenu .close-icon {
    width: 44px;
    top: 23px;
    right: 0;
    position: absolute;

}

.cheeseburgerMenu.menuStorage {
    background-color: #282B36;
}

.menuStorage li {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 25px;
    cursor: not-allowed;
    padding: 10px 20px;
    color: #717688;
    margin:20px 0;
}

.menuStorage li.current {
    cursor: pointer;
    background-color: #717688;
    border-radius: 30px;
    color: #282B36;
    font-weight: bold;
}

.menuStorage li img {
    margin-right: 40px;
}



.mrSite .cheeseburgerMenu {
    background-color: rgba(19,58,99,1);
}

.JWESite .cheeseburgerMenu {
    background-color: rgba(0,0,0,1);
}


.cheeseburgerMenu .logo {
    /*margin:0 auto;
    margin-bottom: 2.5rem;*/
    position: absolute;
    top:10px;

}

.cheeseburgerMenu-inner {
    width:80%;
}

.menu {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
}

.menu-item {
    cursor: pointer;
    padding: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color:#000;
    border-color: #fff;
    border-bottom-width: 2px;
    font-size: 26px;
}

.menu-item.current {
    color: #2A3474;
    
}

.menu-item:hover{
    color: #2A3474;
}

.mrSite .menu-item {
    cursor: pointer;
    padding: 1rem;
    font-weight: normal;
    text-transform: none;
    color:#fff;
    border-color: #fff;
    border-bottom-width: 2px;
    font-size: 20px;
}

.mrSite .menu-item.current {
    color:#FDDC00;
    
}

.mrSite .menu-item:hover{
    color: #FDDC00;
}

.JWESite {
    background-color: #000;
}

.JWESite .menu-item.current {
    color:#80CCE7;
    border-bottom-width: 0;
    text-decoration: underline;
    
}

.JWESite .menu-item:hover{
    color: #80CCE7;
    border-bottom-width: 0;
    text-decoration: underline;
}

.JWESite .menu-item {
    cursor: pointer;
    padding: .8rem;
    font-weight: normal;
    text-transform: none;
    color:#fff;
    border-color: transparent;
    border-bottom-width: 2px;
    font-size: 20px;
    margin:0.2rem 1rem ;
}



.mrSite-home {
    color:rgba(19,58,99,1);
}

.mrSite-home h1 {
    font-size: 40px;
    font-weight: bold;
}

.mrSite-town-concil-item img{
    min-width: 273px;
    height: 322px;
    object-fit: cover;
    max-width: 100%;
    margin-bottom: 20px;
}

.mrSite-town-concil-item {
    border-bottom: 1px solid rgb(19,58,99);
    padding: 40px 0;
}

.mrSite-localBusiness-content {
    display: flex;
    flex-direction: column-reverse;
    margin: 40px 0;
    margin-bottom: 0;
}

.mrSite-localBusinessimg-container img {
    margin-bottom: 40px;
    width: 100%;
}


.mrSite-whatsnewimg-container img {
    width: 100%;
}

.mrSite-localBusiness-content .mrSite-localBusinessimg-container p {
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom: 2px solid rgb(19,58,99);
    font-weight: bold;
    margin-bottom: 40px;

}


.mrSite-safety-content-bottom {
    margin-top: 40px;
    padding: 40px;
    border-bottom: 2px solid rgb(19,58,99);
    border-top: 2px solid rgb(19,58,99);
}

.mrSite-safety-ribbon-section{
    margin-bottom: 40px;
}

.link {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}
.mrSite-localBusiness-content p {
    margin-bottom: 40px;
}

.mrSite ol {
    list-style: decimal inside none;
}

.mrSite ol li {
    margin-bottom: 20px;
}

.mrSite-safety .mrSite-localBusiness-content .mrSite-localBusinessimg-container img {
    margin-bottom: 20px;
}

.JWESite-home {
    background-color: #000;
    color: #fff;
}

.JWESite-home-content.flex {
   flex-direction: column;
   align-items: center;
   margin-bottom: 30px;
}

.JWESite-currentprop-content.flex, .JWESite-pastproject-content.flex {
   flex-direction: column;
   align-items: center;
   margin-bottom: 30px;
}

.JWESite-pastproject-content > div:first-child{
    margin-bottom: 25px;
}


.JWESite-pastproject-content > div > img {
    margin-bottom: 20px;
}


.JWESite-currentprop-content > div {
    display: flex;
    margin-top: 25px;
}

.JWESite-currentprop-content > div img {
    width: 50%;
}
.JWESite-currentprop-content > div img:nth-child(2) {
    padding-left: 12.5px;
}

.JWESite-currentprop-content > div img:first-child {
    padding-right: 12.5px;
}

.JWESite-home h1 {
    text-transform: uppercase;
    font-size: 36px;
    font-weight: bolder;
    color: #6EB0C7;
    border-bottom: 4px solid #6EB0C7;
    margin-bottom: 20px;
    
 }

 .JWESite-home h2{
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: bold;
 }

.JWESite-home h3{
    color: #B05551;
    font-weight: bolder;
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 4px solid #B05551;
}

.JWESite .red-text{
    color: #CC625E;
}
.JWESite .blue-text {
    color: #6EB0C7;
    font-size: 30px;
    font-weight: bold;
}
.JWESite .hr {
    background:#CC625E;
    width: 100%;
    height: 4px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.menu-item:last-child {
    border-color: #fff;
    border-bottom-width: 0px;

}


.ps__rail-y, .ps__rail-x{
    z-index: 5;
}


.about-img-just4Fun {
    flex-basis:40%; 
    align-self: center;
}

.about-img-just4Fun img {
    width:100%;
}

.about-txt-just4Fun {
    flex-basis:60%;
    align-self: center;
    text-align: center;
    padding: 30px 0;
}

.about-txt-just4Fun h2 {
    margin-bottom: 3%;
    font-weight: bold;
    font-size: 30px;
}


.about-container .divider {
    width: 60px;
    height: 5px;
    background-color: #D9DD50;
    margin: 0 auto;
    margin-bottom: 7%;
    margin-top: 4%;
}

.about-txt-just4Fun p {
    
    width: 90%;
    margin: 0 auto;
}

.home-banner-just4Fun img{
    width: 100%;
}

.sectionMerch {
    background-color: #2A3474;
    color:#fff;
    flex-direction: column;  
}


.sectionMerch .about-img-just4Fun {
    background-color: #E4F5FE;
    padding: 50px;
}



.sectionAbout, .sectionForum{
    flex-direction: column-reverse;
}


.signinJust4Fun .login-box {
    margin: auto;
    background-color: #2A3474;
    width: 100%;
    height: 100%;
    max-width: 100%;
    font-weight: 200;
}

.signinJust4Fun .login-box .annoucement {
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
}

.signinJust4Fun .error {
    font-weight: 200;
    font-size: 16px;
}

.signinJust4Fun .login-btn{
    background-color: #559FC9;
    font-size: 30px;
    margin: 0 auto;
    font-weight: 200;

}

.login-box .forgotPassword{
    text-decoration: none;
    text-align: center;
    display: block;
    color: #DCDCDC;
    font-size: 20px;
}



.profile-box {
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;

}

.profileInfosJust4Fun{
    flex-basis: 36%;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 10px;

}

.profileInfosJust4Fun h3, .profileSettingsJust4Fun h3{
    font-size: 26px;
}

 .profileSettingsJust4Fun p{
    font-size: 20px;
 }

.profileSettingsJust4Fun {
    background-color: #C43B34;
    flex-basis: 64%;
}

.sectionAbout1{
    flex-direction: column;
}
.sectionAbout2{
    flex-direction: column-reverse;
}

.about-container .about-txt-just4Fun,.about-container  .about-img-just4Fun {
    flex-basis: 50%;
}

.about-txt-just4Fun {
    width: 100%;
}

.merch-container, .newsletterSuccessMsg{
    margin: auto;
    font-size: 20px;
}

.products-container >div{
    flex-basis: 30%;
    cursor: pointer;
    margin-bottom: 40px;
}

.products-container img {
    margin: 0 auto;
}

.products-container {
    color: #C43B34; 
    flex-direction: column;
    margin: 0 10%;
    justify-content: space-between;
}

.forum-container{
    margin: 0 auto;
    margin-top: 10px;
    max-width: 800px;
}

.forum-container h2 {
    color:#2A3474;
    font-weight: bold;
    margin: 0 20px 5px 20px;
    font-size: 30px;
    margin-top: 20px;
}

.forum-thread-container {}


.thread-content {
    margin-bottom: 20px;
}
.thread-header {
    background-color: #E7F5F8;
    padding: 10px 20px;
    cursor: pointer;
}


.thread-username {
    padding-top: 10px;
}
.thread-header p:not(.thread-username) {
    margin-bottom: 20px;
}

.thread-header h3 {
    color: #AFAFAF;
    font-weight: bold;
    font-size: 32px;
    margin-top: 10px;
    line-height: 36px;
}

.thread-header h3.thread-title-margin{
    margin-bottom: 80px;
}


.forum-container .divider {
    width:100%;
    height: 1px;
    background-color:  #AFAFAF;
}


.thread-reply {
    
    margin: 0 20px;
    margin-right: 0;
    
    margin-top: 20px;
}

.thread-reply-container {
    margin-right: 20px;
}

.border-left {
    border-left: 2px solid #979797;
    padding-left: 20px;
}

.reply-btn {
    text-align: right;
    color: #797979;
    cursor: not-allowed;
}

.thread-comment{
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.thread-comment input {
    width: 100%;
    background: #D8D8D8;
    border-radius: 9px;
    padding: 10px 5px;
    font-weight: bold;
    margin-top: 20px;
}

.reply-username{
    color: #2A3474;
}


.forum-image-container {
    margin: 30px 0;
}

.forum-image-container img {
    margin-bottom: 10px;
    max-width: 100%;
}


.clear {
    clear: both;
}


.inboxcontainer{
    background-color: #fff;
}

.inboxcontainer h3{
    margin-left: 28px; 
    font-size: 30px;
    text-transform: capitalize;
    border-bottom-width: 1px;
    padding: 5px 0;
}




.hamburgermenu {
    width: 30px;
    height: 20px;
    position: absolute;
    display: inline-block;
    border-top: 4px solid white;
    left: 10px;
    top: 14px;
}
.hamburgermenu:after, .hamburgermenu:before {
    content: '';
    width: 100%;
    height:4px;
    background-color:white;
    position:absolute;
}
.hamburgermenu:after {
    bottom:0px;
    left:0;
}
.hamburgermenu:before {
    top:4px;
    left:0;
}



.cheeseburgerMenu .header {
    width:100%;
}



.cheeseburgerMenu .newmessage, .menuDesktop .newmessage,.newEmailBox .newmessage{
    background-color: #454545;
    color:white;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.menuDesktop .newmessage{
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
}

.newEmailBox .newmessage {
    margin-left: 0;
    margin-right: 0;
    width: 100px;
}


button:disabled {
    opacity: 0.6;
}

.menuContent {
    width: 100%;
}

.menuList ul {
    margin-top: 2rem;
}

.menuList li {
    padding: 10px 0px;
    border-bottom: 1px solid grey;
    margin: 0 20px;
    cursor: pointer;

}


.menuDesktop {
    width: 30%;
    border-right: 1px solid grey;
    margin: 10px 0;
}

.newEmailBox{
    background-color: #B3B3B3;
    width: 100%;
    margin-top:15px;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}

.newEmailBox textarea {
    width: 100%;
    flex: 1 1;
    font-size: 12px;
}


.inputContainer label {
    width: 70px;
    display: inline-block;
}


.inputContainer {
    margin-bottom: 1rem;
    font-size: 12px;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0;
    background: rgba(255,255,255,.8);
}

.overlay .login-box {
    background-color: #282B36;
}


.email-item{
    position: relative;
}

.email-item .unread {
    background-color: #147EFB;
    width:10px;
    height:10px;
    position:absolute;
    border-radius:5px;
    top:15px;
    left:6.5px;
}

.email-item-content {
    border-bottom-width: 1px;
    border-bottom-color: #DFDDDE;
    padding-top:10px;
    padding-bottom:10px;
    display: flex;
    flex-direction:row;
    align-items:center;
    margin-left:25px;
}

.email-item-content .infos{
    flex:1;
}

.email-item-content .from {
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
}

.email-item-content .subject{
    font-size:12px;
}

.email-item-content .details{
    flex-direction: row; 
    align-items: center;
    margin-right: 20px;
    color:#147EFB;
    font-weight: bold;
}


.emailViewer{
    padding-left: 20px;
    padding-right: 20px;
}

.emailViewer .contentEmail{
    height: 10px;
    background-color: #fff;
}

.emailViewer .emailHeader {
    padding-top:15px;
    padding-bottom: 15px;
    border-bottom-color:#fff;
    border-bottom-width: 1px;
    flex-direction:row;
    align-items: center;
    display: flex;
    margin-bottom:15px;
}

.emailViewer .initials {
    width:40px;
    height:40px;
    background-color:#fff;
    border-radius:50px;
    margin-right:10px;
}

.emailViewer .textInitials {
    line-height:40px;
    font-size:16px;
    text-align:center;
    color:#000;
    font-weight:bold;
}
.emailViewer .subject {
    font-weight:bold;
    font-size:18px;
    padding:10px;
    background-color: #fff;
}


.emailViewer .rtfdoc {
    padding-top: 0px;
}

.bold {
    font-weight: bold;
}

.flexInbox{
    height: 20px;
}

.clickable {
    cursor: pointer;
}

.clickable:hover{
    opacity: 0.7;
}

.forum-audio-player{
    margin:10px 0;
    width: 100%;
}

.forum-audio-player audio {
    max-width: 100%;
}

.overlaypw {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000000000;
}
.audioViewer, .videoViewer{
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #333337;
    flex-direction: column;
}
.videoViewer > div {
    max-width: 100%;
    
}

.videoViewer {
    align-items: flex-start;
}

.audioViewerContent{
    width: 100%;
}

.player-item {
    margin: 20px ;
}

.audio-controls{
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    color:#fff;
}

.audio-controls button {
    margin: 0 10px;
}


.HAKEmailChatContainer {
    flex:1;
    display: flex;
    flex-direction: column;
}

.HAKEmailChatContainer .container {
    flex:1;
    overflow: auto;
}

.HAKEmailChatContainer form {
    border-top: 1px solid #4A4E5B;
    padding: 20px;
}

iframe, .video{
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px; 
}



.modalcontainer .HAKEmailChatContainer .row {
    margin-left:0;
    margin-right:0;
}

.modalcontainer .HAKEmailChatContainer .label-data{
    font-weight: 300;
    line-height: 1.5;
    color: #495057;
}


.modalcontainer .HAKEmailChatContainer textarea.form-control:focus, .modalcontainer .HAKEmailChatContainer button:focus {
    box-shadow:none;
}

.keepsafe-container.message-container-content .menuStorage {
    flex:1;
}

.chat-thread:first-of-type {
    border-top: 1px solid #4A4E5B;
}

.chat-thread {
    border-bottom: 1px solid #4A4E5B;
}

.keepsafe-container.message-container-content .menuStorage h2 {
   color: #BDBDBD;
   font-size: 30px;
   font-weight: 500;
   text-align: center;
   padding: 30px 0;
}

.chat-thread:hover, .chat-thread.selected{
    background-color: rgba(216,216,216,0.1);
}

.ashleysOfflineMessageSite .chat-thread:hover, .ashleysOfflineMessageSite .chat-thread.selected {
    moz-box-shadow: inset 0 0 10px #c2c2c2;
    box-shadow: inset 0 0 10px #c2c2c2;
    background-color: #fff;
}

.main-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.app_attachement {
    margin-top: 50px;
    display: block;
}

.app_attachement img {
    width: 100px;
    max-width: 100px;
}

.app_attachement.blog_upload_attachement {
    margin-top: 0;
    display: inline-block;
    font-weight: bold;
    color: #9EB943;
    text-decoration: underline;
}
.app_attachement.blog_upload_attachement:hover {
    margin-top: 0;
    display: inline-block;
    font-weight: bold;
    color: #9EB943;
    text-decoration: underline;
    opacity: 0.9;
}

.show {
    display: block;
}

.hide {
    display: none;
}
@media only screen and (min-width: 800px) {
    .blog_excerpt_container .wistia_embed {
        width: 400px;
        height: 300px;
    }
    .keepsafe-container {
        overflow: initial;

    }

    .ashleysOfflineMessageSite .back-arrow {
        width: 40px;
        top: 27px;
    }
    .ashleysOfflineMessageSite .keepsafe-container.message-container-content .HAKEmailChatContainer .chat-jpg {
        width: 90px;
    }
    .ashleysOfflineMessageSite .add-button-chat img {
        width: 68px;
        min-width: 68px;
    }
    .chat-thread.defaultSelected{
        background-color: rgba(216,216,216,0.1);
    }

    .ashleysOfflineMessageSite .header h1 {
        font-size: 30px;
    }
    .keepsafe-container.message-container-content .menuStorage {
        width:300px;
        max-width: 300px;
    }

    .keepsafe-container.message-container-content .menuStorage {
        border-right: 1px solid #4A4E5B;
    }

    .ashleysOfflineMessageSite .keepsafe-container.message-container-content .menuStorage {
        border-right: 1px solid #979797;
    }

    .keepsafe-container.message-container-content .keepsafe-container-content.desktop  {
        display: flex;
    }

    .send-button-chat {
        right: 27px;
        width: 34px;
        top: 20px;
    }

    .HAKEmailChatContainer form {
        padding: 40px;
    }

    .HAKEmailChatContainer .hak-chat-received , .HAKEmailChatContainer .hak-chat-sent{
        font-size: 16px;
        font-weight: 500;
    }

    .HAKEmailChatContainer .hak-chat-received p {
        margin:0;
    }

    .keepsafe-container.message-container-content .HAKEmailChatContainer .form-control {
        padding: 2rem 1.75rem;
        font-size: 16px;
    }
    .comment-container .avatar {
        width: 90px;
        min-width: 90px;
    }
    .ashleyBlog-container  .blog-container {
        flex:1;
    }
    .ashleyBlog-container .side-bar {
        width: 30%;
        max-width: 470px;
    }

    .ashleyBlog-container .side-bar img {
        width: 60%;
    }

    .ashleyBlog-container {
        flex-direction: row;
    }
    .ashleyBlogSite h1 {
        font-size: 80px;
        margin-bottom: 45px;

        
    }

    .ashleyBlogSite {
        padding-top: 154px;
    }
    .ashleyBlogSite .header {
        height: 124px;
        min-height: 124px;
        justify-content: center;
    }

    .ashleysOfflineMessageSite .header {
        height: 90px;
        min-height: 90px;

    }

    .ashleyBlogSite .footer {
        height: 140px;
        min-height: 140px;
    }

    .ashleyBlogSite .header .close-icon, .ashleysOfflineMessageSite .close-icon {
        width: 40px;
        top: 40px;
        right: 30px;
    }


     .ashleysOfflineMessageSite .close-icon {
        top: 25px;
        right: 25px;
    }

    .ashleysOfflineMessageSite .keepsafe-container {
        margin-top:90px;
    }



    .ashleysOfflineMessageSite .chat-thread.defaultSelected , .ashleysOfflineMessageSite .chat-thread.selected{
        moz-box-shadow: inset 0 0 10px #c2c2c2;
        -webkit-box-shadow: inset 0 0 10px #c2c2c2;
        box-shadow: inset 0 0 10px #c2c2c2;
        background-color: #fff;
    }




    .bottom-imageViewer-controllers.desktop{
        
        position: fixed;
        bottom: 0px;
        left: 0;
        width: calc( 100%);
        height: 80px;
        background-color: #717688;
        
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        justify-content: center;
    }

    .keepsafe-header .back-button {
        position: absolute;
        font-size: 25px;
        text-decoration: underline;
        left: 110px;
        top:25px;
    }

    .keepsafe-header .back-button:focus {
        outline: none;
    }

    .keepsafe-header h2 {
        line-height: 90px;
        font-size: 28px;
    }

    .keepsafe-header .back-button:hover {
        opacity: 0.8;

    }
    .keepsafe-iconlist li{
        padding: 25px 0px;
        width: 162px;
        margin:0 40px;
       
    }
    .keepsafe-iconlist {
        font-size: 28px;
    }
    .keepsafe-container-content {

        margin-left: 6%;
        margin-right: 2%;
    }

    .keepsafe-container.message-container-content .keepsafe-container-content {
        margin-left:0;
        margin-right:0;
    }
    .keep-safe-icon-container {
        height: 80px;
    }
    .menuStorage .menu{
        margin-left: 25px;
        border-bottom: 1px solid #717688;
    }
    .desktop.top-bar {
        display: flex;
        background-color: #373A44;
    }   
    .mobile.top-bar {
        display: none;
    }


    .header-input-container input {
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        border-radius: 15px;
        padding: 10px 40px;
        margin:3% 10%;
        width: calc(100% - 20%);
    }


    .login-box h3{
        font-size: 1.875rem;
        line-height: 2.25rem;
    }

    .login-box button {
        font-size: 1.5rem;
    }

    .footer {
        font-size: 1rem;
        bottom: 20px;
    }

    .footer button {
        font-size: 1.2rem;
        margin-bottom: 15px;
        padding-bottom: 5px;
    }


    .modalcontainer{
        moz-box-shadow:  1px 1px 2px 1px #868686;
       -webkit-box-shadow: 1px 1px 2px 1px #868686;
        box-shadow: 1px 1px 2px 1px #868686;
    }
    .products-container {
        align-items: baseline;
        margin: 0;
    }
    .about-txt-just4Fun {
        width: auto;
    }

    .profileInfosJust4Fun h3{
        height: 40px;
    }

    .profileSettingsJust4Fun{
        margin-top: 40px;
    }

    .profileInfosJust4Fun{
        margin:0;
        margin-right: 20px;
    }
    .signinJust4Fun .login-box {
        height: auto;
        max-width: 600px;
    }


    .signinJust4Fun .login-box .annoucement {

        margin-bottom: 2rem;
    }

    .signinJust4Fun .error {
        margin-top: 0;
    }
    .sectionAbout, .sectionForum, .sectionMerch, .profile-box, .sectionAbout1,.sectionAbout2, .products-container  {
        flex-direction: row;
    }
    .desktop {
        display: block;
    }

    .desktop.flex {
        display: flex;
    }

    .mobile, .ashleyBlogSite .header .menu-toggle-icon {
        display: none;
    }

    .about-txt-just4Fun h2 {
        font-size: 46px;
    }

    .about-txt-just4Fun p {
        font-size: 24px;
        line-height: 26px;
        width: 70%;

    }


    .about-container .about-txt-just4Fun p {
        font-size: 20px;
        line-height: 22px;
    }


    .menu-item{
        display: inline-block;
        padding: 0;
        margin: 0.6rem 1rem; 
        margin-bottom: 0rem;
        color:#2A3474;
        border-color: transparent;
        border-bottom-width: 2px;

    }

    .menu-item.current {
        color: #2A3474;
        border-color: #2A3474;
        border-bottom-width: 2px;
    }

    .menu-item:hover {
        color: #2A3474;
        border-color: #2A3474;
        border-bottom-width: 2px;
    }


    .mrSite .menu-item{
        display: inline-block;
        padding: 0;
        margin: 0.2rem 1rem; 
        margin-bottom: 0rem;
        color:#fff;
        border-color: transparent;
        border-bottom-width: 2px;

    }

    .mrSite .menu-item.current {
        color: #FDDC00;
        border-color: #FDDC00;
        border-bottom-width: 2px;
    }

    .mrSite .menu-item:hover {
        color:#FDDC00;;
        border-color: #FDDC00;
        border-bottom-width: 2px;
    }






    .icon-wrapper{
        width: 80px;
    }
    .rtfdoc table{
        min-width:  100%;
    }
    /*.rtfdoc{
        width: 110%;
        padding-right: calc(20px + 10%);
    }*/

    .rtfdoc ul {
        padding-left: 15px;
        margin-left: 40px;
    }

    object{
        width:auto;
    }


     .bottom-imageViewer-controllers button{
        display: inline-block;
     }


  .App-clock{
    padding-right: 70px;
  }

  .dock-container a{
    padding-left: 70px;
  }

  .modal {
        width:20px;
        height: 25px;
        width: 800px;
        height: 525px;
        min-height: auto;

    }

    .folder-content{
        min-height: 600px;
        
    }

    .content-file{
        max-width: none;
        max-height: none;
        /*min-width: 110%;*/
    }

    .imageViewer{
        overflow: auto;
        margin-bottom: 80px;
    }
    .imageViewer .content-file, .imageViewer .rtfdoc{ 
        
        min-width: 100%;
    }


    .imageViewer img{
        width: 100%;
    }


    /*.ScrollbarsCustom-Content{
        min-width: 110% !important;
        min-height: 110% !important;
    }*/
    .flexInbox {
        display: flex;
        flex-direction: row;
    }

    .menuList li:hover{
        color:#454545;
    }

    .newEmailBox{
        margin:15px;
        border-radius: .5rem;
    }



    .forum-image-container img {
        margin-bottom: 10px;
        max-width: 100%;
        float: left;
        width: calc(25% - 7.5px);
        margin-right: 10px;
    }


    .forum-image-container img:nth-child(4n) {
        margin-right: 0;
    }




    iframe{
        width: 500px;
        height: 300px;

    }

    .thread-header{
        padding: 10px 40px;
    }

    .thread-reply{
        margin: 30px 20px 0 40px;
    }

    .border-left {
        padding-left: 40px;
    }

    .forum-container h2 {
        margin: 40px 0px 30px 0px;
    }

    .newest-thread-section h2 {
        margin-top: 20px;
    }

    .thread-header h3.thread-title-margin {
        margin-bottom: 20px;
    }


    .videoViewer > div {
        width: 100% !important;
        height: 100% !important;
    }


    .desktop.menu-mrsite-container {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: -54px;
        width: 100%;
        padding-left:5%;
    }

    .hero-ribbon {
        bottom: -7px;

    }

    .ribbon {
        height: 14px;
        background-size: 100% 14px;

    }


    .mrSite-home p, .mrSite-home ol {
        font-size: 26px;
        line-height: 40px;
        

    }

    .mrSite-container  {
        padding-top: 90px;
    }

    .signature-container.flex {
        display: flex;
        text-align: left;    
    }

    .signature-container h2 {
        text-align: right;
    }

    .mrSite-town-concil-item {
        display: flex;
        align-items: flex-start;
    }


    .mrSite-town-concil-item {
        padding: 80px 0;
    }

    .mrSite-town-concil-item img {
        margin-bottom: 0
    }

    .mrSite-town-concil-item p {
        padding-left: 140px;
    }

    .mrSite-localBusiness-content {
        display: flex;
        flex-direction: row;
        margin: 60px 0;
        margin-bottom: 0;
    }


    .mrSite-localBusinessimg-container {
        width: 478px;
        min-width: 478px;
        max-width: 100%;
    }

    .mrSite-whatsnewimg-container {
        width: 333px;
        min-width: 333px;
        max-width: 100%;
    }


    .mrSite-localBusinessimg-container img {
        margin-bottom: 60px;

    }
    .mrSite-localBusiness-content p ,.mrSite-localBusiness-content ol{
        padding-left: 60px;
        margin: 0
    }

    .mrSite-safety .mrSite-localBusiness-content p {
        padding-left: 71px;
    }


    .mrSite-safety .mrSite-localBusiness-content .mrSite-localBusinessimg-container img {
        margin-bottom: 20px;

    }

    .mrSite-localBusiness-content .mrSite-localBusinessimg-container p {
        margin: 0;
        padding: 0;
        padding-bottom: 20px;
        border-bottom: 2px solid rgb(19,58,99);
        margin-bottom: 40px;

    }

    .mrSite-safety-content-bottom {
        padding: 80px;
        margin-top:60px;
    }

    .mrSite-safety-ribbon-section{
        margin-bottom: 60px;
    }

    .keepsafe-main-screen > .header {
        padding: 2% ;
        display: flex;
        align-items: flex-end;
    }

    .keepsafe-search-container {
        margin-top: 0;
        flex: 1;

    }

    .keepsafe-search-container img {
        margin:0 auto;
        padding: 0 20px;
    }

    .keepsafe-header-right-icons {
        position:inherit;
        width: auto;
    }

    .keepsafe-main-screen .menu {
    border-right: 2px solid  #243D7E;
    height: 100%;
    }

    .keepsafe-main-screen .menu-item:hover {
        border-color: transparent;

    }
    .keepsafe-main-screen .menu-item {
        display: flex;
        
        padding: 10px;
        padding-left:50px;
        margin-right: 30px;
        
        margin-left: 0;

    }

    .keepsafe-main-screen .menu-item:last-child {
        margin-left: 50px;
        padding-left: 0px;
        border-top: 2px solid  #243D7E;
        padding-top: 30px;
        margin-top: 20px;

    }

    .keepsafe-main-screen .menu-item:first-child {
        background-color: rgba(42,52,116,0.5);
         opacity: 1;
         cursor: pointer;
         border-top-right-radius: 40px; 
         border-bottom-right-radius: 40px; 

    }

    .JWESite .header .logo {
        margin-top: 30px;
    }

    .JWESite .menu {
        padding: 0;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        margin-top: 20px;
    }

    .just4FunSite-container.JWESite-container {
        /*padding-right: 300px;*/
        flex-direction: row;
        align-items: flex-start;
    }

    .JWESite-pizza-ad-container {
         position: relative;
        width: 250px;
        min-width: 250px;
        margin-left: 50px;
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom:0;
    }

    .JWESite-pizza-ad {
        position: fixed;
        /*right: 1%;*/
    }

    .jordan-realtor {
        min-width: 260px;
        max-width: 260px;
    }



.JWESite-home-content.flex {
    flex-direction: row;
    align-items: flex-start;
}

.JWESite-home-content p {
    margin-left:50px;
    font-size: 20px;
}

.JWESite-currentprop-content.flex, .JWESite-pastproject-content.flex {
    flex-direction: row;
    align-items: flex-start;
}
.JWESite-pastproject-content > div{
    width: 50%;
}


.JWESite-pastproject-content > div:first-child{
    margin-bottom: 0;
    margin-right: 25px;
}

.JWESite-pastproject-content > div:nth-child(2){
    margin-left: 25px;
}


.JWESite-currentprop-content > img {
    width: 63%;
    margin-right: 50px;
}

.JWESite-currentprop-content > div img {
    width: 100%;
}

.JWESite-currentprop-content > div img:nth-child(2){
    padding: 0;
}

.JWESite-currentprop-content > div img:first-child {
    margin-bottom: 50px;
    padding: 0;
    
}

.JWESite-currentprop-content > div {
    display: block;
    margin: 0;
}

 .JWESite-home h2{
    font-size: 30px;
}

}

@media only screen and (max-width: 1060px) {
    .mrSite .menu-item {
        font-size: 14px;
        font-weight: bold;
    }

    .menu-mrsite-container .logo{
        width: 120px;
    }

    .desktop.menu-mrsite-container {
        bottom: -23px;
    }


}

@media only screen and (min-width: 1200px) {

    .about-txt-just4Fun h2 {
        font-size: 70px;
    }

    .about-txt-just4Fun p {
        font-size: 34px;
        line-height: 38px;

    }


    .about-container .about-txt-just4Fun p {
        font-size: 28px;
        line-height: 30px;
    }

/*
    .JWESite-pizza-ad {
        position: absolute;
        right:4.5%;
        margin: 0;
    }*/

}