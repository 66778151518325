.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
	outline: none;
	border: 2px solid #A9654C !important
}

@media(max-width: 768px) {
	.image-gallery-thumbnail.active,
	.image-gallery-thumbnail:focus {
		border: 3px solid #A9654C !important
	}
}

@media(hover: hover)and (pointer: fine) {
	.image-gallery-thumbnail:hover {
		outline: none;
		border: 2px solid #A9654C !important
	}
}

@media(hover: hover)and (pointer: fine)and (max-width: 768px) {
	.image-gallery-thumbnail:hover {
		border: 3px solid #A9654C !important
	}
}

.image-gallery-bullets .image-gallery-bullet:focus {
	transform: scale(1.2);
	background: #A9654C !important;
	border: 1px solid #A9654C !important
}

.image-gallery-bullets .image-gallery-bullet.active {
	transform: scale(1.2);
	border: 1px solid #fff;
	background: #fff
}

@media(hover: hover)and (pointer: fine) {
	.image-gallery-bullets .image-gallery-bullet:hover {
		background: #A9654C !important;
		border: 1px solid #A9654C !important
	}
	.image-gallery-bullets .image-gallery-bullet.active:hover {
		background: #A9654C !important
	}
}

@media(hover: hover)and (pointer: fine) {
	.image-gallery-icon:hover {
		color: #A9654C !important
	}
	.image-gallery-icon:hover .image-gallery-svg {
		transform: scale(1.1)
	}
}

.image-gallery-icon:focus {
	outline: 2px solid #A9654C !important
}