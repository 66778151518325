// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.

$color-primary:              #343a40;
$color-secondary:            rgb(0,122,255);
$color-chat-sent:            #626779;
$color-chat-sent-text:       #fff;
$color-chat-received:        #353A4D;
$color-chat-received-text:   #fff;
$color-background:           gray;
$color-break:                #ddd;
$color-container-border:     #dedede;

$font-family:                Calibri, "Helvetica Neue", Arial, sans-serif;

$font-size-smallest:         .25rem;
$font-size-small:            .75rem;
$font-size-base:             0.8rem;
$font-size-large:            0.9rem;
$font-size-larger:           1.25rem;
$font-size-largest:          2rem;
$font-size-huge:             4rem;

$font-weight-lighter:        100;
$font-weight-light:          200;
$font-weight-base:           300;
$font-weight-dark:           500;
$font-weight-darker:         700;
$font-weight-darkest:        900;

$h1-font-size: $font-size-largest;
$h2-font-size: $font-size-larger;
$h3-font-size: $font-size-large;
$h4-font-size: $font-size-large;
$h5-font-size: $font-size-small;