/* Site colors, font sizes, etc. are defined here. */
@import "./src/theme/variables.scss";

/* ==============================================================================
    Import bootstrap styles within class to isolate css impact when components
    are imbedded in other sites.
================================================================================= */
.HAKEmailChatContainer {
    
    margin: 1rem auto 1rem auto;
    margin-top:0;
    font-family: $font-family;
    font-size: $font-size-base;
    -webkit-text-size-adjust: 100%;

    &.m-0{
        margin:0;
    }
    /* Site built on Bootstrap */
    @import "~bootstrap/scss/bootstrap";
    .btn{
    padding:0;
    }

    .hak-container{
        height:100%;
    }

    .form-group {
    margin-bottom:0;
    }
    /* ==============================================================================
        HTML element and general styling
    ================================================================================= */
   

    .hak-hr {
        color:$color-break;
        background-color: $color-break; height:1px;
        border: none;
        max-width: 100%;
    }

    .hak-avatar-img {
        max-width: 2rem !important;
        height: 2rem !important;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: top;
    }

    .hak-read, .hak-unread {
        height: 0.75rem;
        width: 0.75rem;
        background-color: $color-secondary;
        border-radius: 50%;
        margin-right: 0.4rem;
        display: inline-block;
        margin-top: 0.625rem;
    }

    .hak-read {
        background-color: transparent;
    }

    .hak-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    .hak-add-email {
       /* position:fixed;
        bottom:10px;
        right:10px;*/
        background-color: $color-secondary;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;
        border-radius: 5px;
        float:right;
        cursor:pointer;
    }

    .hak-email-chain {
        opacity: 45%;
    }

    .hak-email-sent-toast {
        background-color: $color-background;
        position: fixed;
        top: 25%;
        left: 45%;
        transform: translate(-25%, -45%);
        z-index: 99;
    }

    .hak-chat-input {
        width: 100%;
        display: inline-block;
    }



    .hak-chat-sent {
        color: $color-chat-sent-text;
        background-color: $color-chat-sent;
        border-radius: 0.8rem;
    }

    .hak-chat-received {
        color: $color-chat-received-text;
        background-color: $color-chat-received;
        border-radius: 0.8rem;
    }

    .hak-search {
        position: relative;
        display: inline-block;
        background: #fff;
        border-radius: 50px;
        height: 12px;
        width: 12px;
        border: 2px solid #888;

        &:after {
            content: "";
            height: 3px;
            width: 6px;
            background: #888;
            position:absolute;
            top: 8px;
            left: 6px;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
        }
    }

    .hak-emaillist-avatar {
        flex: inherit;
        display:flex;
        width: 59px;
    }

    .hak-emaillist-date{
        font-size:0.7rem;
    }

    .hak-emaildetail-avatar {
        flex: inherit;
        width: 40px;
    }


    .hak-menu-section {
        border-right: 1px solid grey;


        .desktop {
            .btn {
                border-radius:0;
                border-bottom: 1px solid grey;
                text-align:left;

                &.btn-selected {
                    background-color:$color-background;
                    color:#fff;
                }
            }
            
        }
    }


  

     .mobile{
        display:none;
    }
    .desktop{
        display:block;
    }


    .hak-new-email-form {
        .form-label {
        width:35px;
        }


        display: flex;
        flex-direction: column;
    }

    .hak-new-email-text-area-container{
        flex:1;

        .row{
            height:100%;
        }
    }


    /* Small devices (tablets, 650px and down) */
    @media screen and (max-width: 650px) {
        .mobile{
            display:block;
        }
        .desktop{
            display:none;
        }
        .hak-email-sent-toast {
            left: 35%;
            transform: translate(-25%, -35%);
        }

        .hak-inbox-container{
        padding:0;
        }

        .form-control {
            height: calc(1.5em + 0.5rem + 2px);
        }

        .hak-menu-section {
            border-right: none;
        }

        .hak-container{
            height:auto;
            overflow: hidden;

        }

    }
}
